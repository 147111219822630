import React from "react";
import { dataClient } from "../../data/DataClient";
import "./Client.css";

const Client = () => {
  return (
    <div className="client-container" id="client">
      <div className="client-title">
        <img src="./assets/client.png" alt="" />
      </div>
      <div className="client-content">
        {dataClient.map((client, i) => (
          <img key={i} src={client.src} alt="" />
        ))}
      </div>
    </div>
  );
};

export default Client;
