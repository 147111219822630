import React, { useMemo } from "react";
import Modal from "react-modal";
import "./ModalServices.css";
import { dataModal } from "../../data/DataModal";

const ModalServices = (props) => {
  const data = useMemo(
    () => dataModal[props.selectedIndex],
    [props.selectedIndex]
  );
  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      contentLabel="Example Modal"
      className="modal"
      overlayClassName="overlay"
    >
      <div className="modal-content">
        <div className="modal-content-image">
          <img src={data?.src} alt="" />
        </div>
        {data?.text}
      </div>
      <div className="btn-close">
        <button className="btn-close" onClick={props.closeModal}>
          close
        </button>
      </div>
    </Modal>
  );
};

export default ModalServices;
