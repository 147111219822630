import React, { useState, useEffect } from "react";
import "./Footer.css";

const Footer = () => {
  const [show, setShow] = useState(false);
  const year = new Date().getFullYear();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShow(true);
      } else setShow(false);
    });
  }, []);
  return (
    <div>
      <div className={`${show ? "footer-dark" : "footer"}`}>
        <div className="footer-content">
          <span style={{ color: "#fff" }}>Trans Media Sosial @ {year}</span>
          <div className="sosmed-content">
            <a
              // aria-label="Chat on WhatsApp"
              target="_blank"
              rel="noopener noreferrer"
              href="https://wa.link/s09ob2"
            >
              <img
                src="/assets/Logo/logo-whatsapp.png"
                alt="logo-whatsapp"
                width={25}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/transmediasosial/"
            >
              <img
                src="/assets/Logo/logo-ig.png"
                alt="logo-instagram"
                width={25}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tiktok.com/@creative.tms?is_from_webapp=1&sender_device=pc"
            >
              <img
                src="/assets/Logo/logo-tiktok.png"
                alt="logo-tiktok"
                width={25}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/transmediasosial/mycompany/"
            >
              <img
                src="/assets/Logo/logo-linkedin.png"
                alt="logo-linkedin"
                width={25}
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/transmedsos"
            >
              <img
                src="/assets/Logo/logo-twitter.png"
                alt="logo-twitter"
                width={25}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
