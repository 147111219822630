import React from "react";

import "../../App.css";
import Navbar from "../../components/Navbar";
import Hero from "../../components/Hero";
import Services from "../../components/Services";
import TheHype from "../../components/TheHype";
import Client from "../../components/Client";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Services />
      <TheHype />
      <Client />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
