export const bucketUrl = "https://bucket.tms.id/media";
export const dataModal = [
  {
    src: `${bucketUrl}/smm.png`,
    text: (
      <div className="modal-desc">
        <h4>SOCIAL MEDIA MONITORING</h4>
        <span>
          Pantau kondisi brand Anda di mata audiens, bagaimana sentimennya,
          ekuitas brand, persepsi audiens serta analisis target audiens untuk
          meningkatkan nilai brand Anda.
        </span>
        <ul>
          <li>Analisa performa sosial media</li>
          <li>Analisa hubungan jejaring sosial</li>
          <li>Penanganan crisis</li>
          <li>Analisa kompetitor</li>
          <li>Sentimen dan potensial isu</li>
          <li>Tren industri</li>
        </ul>
      </div>
    ),
  },
  {
    src: `${bucketUrl}/smc.png`,
    text: (
      <div className="modal-desc">
        <h4>SOCIAL MEDIA COMMUNICATION</h4>
        <span>
          Optimalisasi paparan dari brand melalui sosial media lewat analisa,
          kampanye digital, dan strategi komunikasi untuk berinteraksi dengan
          target audience Anda.
        </span>
        <ul>
          <li>Perencanaan dan strategi sosial media</li>
          <li>Produksi konten sosial media</li>
          <li>Iklan digital dan sosial media</li>
          <li>Pelaporan sosial media</li>
        </ul>
      </div>
    ),
  },
  {
    src: `${bucketUrl}/pr.png`,
    text: (
      <div className="modal-desc">
        <h4>PUBLIC RELATION</h4>
        <span>
          Membangun citra perusahaan melalui pemberitaan yang berkelanjutan.
          Media online ataupun cetak masih sangat relevan untuk menjadi alat
          komunikasi kepada publik karena memiliki pembacanya sendiri, khususnya
          Gen Y & Gen X.
        </span>
        <ul>
          <li>Pembuatan press rilis</li>
          <li>Daftar preferensi media</li>
          <li>Pendistribusian media</li>
          <li>Pelaporan hasil media</li>
        </ul>
      </div>
    ),
  },
  {
    src: `${bucketUrl}/web-dev.png`,
    text: (
      <div className="modal-desc">
        <h4>WEB DEVELOPMENT</h4>
        <span>
          Pikat pelanggan Anda dengan memberi mereka informasi yang berguna
          tentang bagaimana Anda dapat memecahkan masalah mereka melalui
          website, kemudian munculkan website Anda di halaman pertama pencarian
          google.
        </span>
        <ul>
          <li>UI & UX Design</li>
          <li>Frontend Development</li>
          <li>Backend Development</li>
          <li>IT Infrastruktur</li>
          <li>Search Engine Optimization</li>
        </ul>
      </div>
    ),
  },
  {
    src: `${bucketUrl}/seo.png`,
    text: (
      <div className="modal-desc">
        <h4>SEARCH ENGINE OPTIMAZATION</h4>
        <p>
          Kami menyediakan strategi kampanye efektif untuk meningkatkan
          visibilitas di platform mesin pencari. Kombinasi teknikal dan konten
          terbukti mendorong hasil kesuksesan jangka panjang untuk memancing
          audiens tertarik dengan brand Anda.
        </p>
        <p>
          Strategi konten situs web yang terbaik dengan pendekatan persona
          konsumen brand Anda adalah praktik terbaik SEO. Tujuannya untuk
          memaksimalkan peluang untuk meningkatkan branding perusahaan Anda di
          dunia digital.
        </p>
      </div>
    ),
  },
  {
    src: `${bucketUrl}/digi-ads.png`,
    text: (
      <div className="modal-desc">
        <h4>DIGI ADS</h4>
        <ul>
          <li>Iklan Meta (Facebook & Instagram)</li>
          <li>Iklan platform Google</li>
          <li>Buzzer dan sentimen positif</li>
        </ul>
      </div>
    ),
  },
];
