import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <h1 style={{ color: "white" }}>
        Oops! Page not found. You seem to be lost.
      </h1>
      <p style={{ color: "white" }}>Here are some helpful links:</p>
      <Link to="/">Home</Link>
    </div>
  );
}
