import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Report.css";
import { dataReport } from "../../data/DataReport";

const Report = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const reportItem = dataReport.find((item) => item.slug === slug);

  if (!reportItem) {
    navigate("/");
  }

  return (
    <>
      {reportItem && (
        <iframe src={reportItem.link_report} title="Report"></iframe>
      )}
    </>
  );
};

export default Report;
