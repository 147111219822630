import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact-container" id="contact">
      <div className="contact-title">
        <img src="./assets/dontmissthehype.png" alt="" />
      </div>
    </div>
  );
};

export default Contact;
