import React, { useState, useEffect } from "react";
import "./Navbar.css";

const Navbar = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShow(true);
      } else setShow(false);
    });
  }, []);
  return (
    <div className={`top-nav ${show && "nav-dark"}`}>
      <div className="logo">
        <a href="#hero">
          <img src="/assets/Logo/Logo-tms.png" alt="logo-tms" width={50} />
        </a>
      </div>
      <input id="menu-toggle" type="checkbox" />
      <label className="menu-button-container" htmlFor="menu-toggle">
        <div className="menu-button"></div>
      </label>
      <ul className="menu">
        <li>
          <a href="#services">
            <span>Services</span>
          </a>
        </li>
        <li>
          <a href="#thehype">
            <span>The Hype</span>
          </a>
        </li>
        <li>
          <a href="#client">
            <span>Client</span>
          </a>
        </li>
        <li>
          <a href="#contact">
            <span>Contact Us</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
