export const dataReport = [
  {
    title: "Report PKT",
    slug: "pkt",
    link_report:
      "https://report.tmsosial.asia/index.html?file=PKT.pdf#megazineMode=true",
    image: "https://bucket.tms.id/media/logo_pkt.webp",
  },
  {
    title: "Report Pefindo",
    slug: "idscore",
    link_report:
      "https://report.tmsosial.asia/index.html?file=AnnualReportIDScore.pdf#megazineMode=true",
    image: "https://bucket.tms.id/media/logo_pkt.webp",
  },
];
