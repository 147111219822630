import React from "react";
import "./Hype.css";

const TheHype = () => {
  return (
    <div className="hype-container" id="thehype">
      <div className="hype-title">
        <img src="/assets/thehype.png" alt="logo-service" />
      </div>
      <div className="hype-content-container">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.saniter.co.id/"
        >
          <img src="/assets/website-saniter.webp" alt="" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.stella-airfreshener.com/"
        >
          <img src="/assets/website-stella.webp" alt="" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://allobank.com"
        >
          <img src="/assets/website-allobank.webp" alt="" />
        </a>
      </div>
      <div className="hype-content-container">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.transluxuryyacht.com/"
        >
          <img src="/assets/website-tly.webp" alt="" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.ademsari.co.id/"
        >
          <img src="/assets/website-ademsari.webp" alt="" />
        </a>
      </div>
      <div className="portfolio-container">
        <div className="portfolio-content-logo">
          <img src="/assets/Logo/logo-mega.png" alt="" />
        </div>
        <div className="portfolio-content-list">
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/CfJn8jbgjVp/"
          >
            <img src="/assets/content-mega1.webp" alt="" />
          </a>
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/CgDxPIkBW-1/"
          >
            <img src="/assets/content-mega2.webp" alt="" />
          </a>
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/CgT-FAjhr5D/"
          >
            <img src="/assets/content-mega3.webp" alt="" />
          </a>
        </div>
        <div className="portfolio-content-logo">
          <img src="/assets/Logo/logo-bpsdmi.png" alt="" />
        </div>
        <div className="portfolio-content-list">
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/CJ8BOAaFEy0/?igshid=YmMyMTA2M2Y="
          >
            <img src="/assets/content-bpsdmi1.webp" alt="" />
          </a>
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/CJ-vq9Vlyqr/?igshid=YmMyMTA2M2Y="
          >
            <img src="/assets/content-bpsdmi2.webp" alt="" />
          </a>
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/CKXw6gWFj22/?igshid=YmMyMTA2M2Y="
          >
            <img src="/assets/content-bpsdmi3.webp" alt="" />
          </a>
        </div>
        <div className="portfolio-content-logo">
          <img src="/assets/Logo/logo-satubsd.png" alt="" />
        </div>
        <div className="portfolio-content-list">
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/CVz-R5OLHj-/?igshid=YmMyMTA2M2Y="
          >
            <img src="/assets/content-satubsd1.webp" alt="" />
          </a>
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/CViBrZ5hGyP/?igshid=YmMyMTA2M2Y="
          >
            <img src="/assets/content-satubsd2.webp" alt="" />
          </a>
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/CT6tQSBJoue/?igshid=YmMyMTA2M2Y="
          >
            <img src="/assets/content-satubsd3.webp" alt="" />
          </a>
        </div>
        <div className="portfolio-content-logo">
          <img src="/assets/Logo/logo-majalengka.png" alt="" />
        </div>
        <div className="portfolio-content-list">
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/CO2IQoqNU9v/"
          >
            <img src="/assets/content-majalengka1.webp" alt="" />
          </a>
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/COfHLaGNJ_8/"
          >
            <img src="/assets/content-majalengka2.webp" alt="" />
          </a>
          <a
            className="btn"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/p/CNrBnIUFn5j/"
          >
            <img src="/assets/content-majalengka3.webp" alt="" />
          </a>
          <div className="portfolio-content-logo">
            <img src="/assets/Logo/logo-alluthfi.png" alt="" />
          </div>
          <div className="portfolio-content-list">
            <a
              className="btn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/p/CcR8lRLLJQs/"
            >
              <img src="/assets/content-alluthfi1.png" alt="" />
            </a>
            <a
              className="btn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/p/CcfTX8mrfNg/"
            >
              <img src="/assets/content-alluthfi2.png" alt="" />
            </a>
            <a
              className="btn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/p/Cccx-bJLph5/"
            >
              <img src="/assets/content-alluthfi3.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheHype;
