import React from "react";
import "./Hero.css";

const Hero = () => {
  const video = "https://bucket.tms.id/media/hero.mp4";
  return (
    <div className="hero-container" id="hero">
      <video loop muted autoPlay playsInline className="video-preview">
        <source src={video} type="video/mp4" />
        Browser tidak support
      </video>
    </div>
  );
};

export default Hero;
