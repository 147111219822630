import React, { useState } from "react";
import "./Services.css";
import { dataModal } from "../../data/DataModal";
import ModalServices from "../Modal/ModalServices";

const Services = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div>
        <ModalServices
          selectedIndex={selectedIndex}
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
        />
      </div>
      <div className="service-container" id="services">
        <div className="service-title">
          <img src="./assets/services.png" alt="" />
        </div>
        <div className="service-content">
          {dataModal.map((element, i) => (
            <span
              onClick={() => {
                setSelectedIndex(i);
                openModal();
              }}
              key={i}
              className="smm"
            >
              <img src={element.src} alt="" />
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export default Services;
