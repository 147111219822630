export const dataClient = [
  {
    src: "./assets/Logo/client-tms-logo/allobank.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/transtv.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/trans7.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/detiknetwork.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/bankmega.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/satubsd.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/berbuatbaik.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/pfi.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/transmart.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/ratu.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/pg.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/trb.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/danone.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/fashionhotel.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/majalengka.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/wings.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/transstudio.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/tsw.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/bpsdmi.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/reckittbenckiser.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/tlh.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/stella.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/wyeth.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/saniter.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/tly.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/djarum.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/plossa.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/indofood.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/whitekoffie.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/enesis.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/fourstar.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/frisianflag.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/ademsari.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/tsm.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/unilever.png",
  },
  {
    src: "./assets/Logo/client-tms-logo/alluthfi.png",
  },
];
